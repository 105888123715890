import "@/styles/app.css";

import { UserProvider } from "@auth0/nextjs-auth0";
import classNames from "classnames";
import Head from "next/head";
import { useRouter } from "next/router";
import NextProgress from 'next-progress';
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Toaster } from "react-hot-toast";

import AdminHeader from "@/components/layout/AdminHeader";
import { MobileNavigation } from "@/components/layout/navigation/MobileNavigation";
import SideNavigation from "@/components/layout/navigation/SideNavigation";
import ApiClient from "@/utilities/ApiClient";

const App = (props) => {

  const {
    Component,
    pageProps: { session, ...pageProps},
  } = props;

  const accessToken = pageProps?.accessToken || null;

  const router = useRouter();
  const isSurveyRoute = router.pathname.startsWith('/response');

  const { user } = pageProps;
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const api = new ApiClient(accessToken);

  const toggleNav = function(open) {
    setMobileNavOpen(open)
  }

  if (isSurveyRoute) {
    return (
      <>
        <Component {...pageProps} key={`component_${router.asPath}`}/>
      </>
    );
  }

    return (
      <UserProvider user={user}>

        <Head>
          {/* TODO This is a lint fail and should be imported by Nextjs: import "@/styles/typekit-akzidenz-grotesk.css"; */}
          {/* eslint-disable-next-line @next/next/no-css-tags */}
          <link
            rel="stylesheet"
            href="/css/typekit-akzidenz-grotesk.css"
            key="typekit"
          />
          <link rel="icon" href="/favicon.ico" />
        </Head>

        <div>
          <Toaster />
        </div>

        <NextProgress delay={200} color="#1db4e6" options={{ showSpinner: false }} />
        <MobileNavigation toggleNav={toggleNav} open={mobileNavOpen}/>

        {user && <AdminHeader api={api}/>}

        <div className={classNames("flex flex-1", mobileNavOpen ? "z-10 overflow-hidden sm:overflow-visible" : "")}>
          <SideNavigation />
          <div
            className="flex flex-col flex-grow mt-20 sm:mt-0 w-[calc(100vw-260px)]"
            style={{ backgroundColor: "#D6F0FA" }}
          >
            <Component {...pageProps} key={`component_${router.asPath}`} />
          </div>
        </div>

      </UserProvider>
    );
};

App.propTypes = {
  Component: PropTypes.any,
  pageProps: PropTypes.object,
};

export default App;
