import { useState } from "react";
import {
  IconNavClients,
  IconNavDashboard,
  IconNavSpeech,
  IconNavSettings,
} from "@/components/icons";
import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import Link from "next/link";
import classNames from "classnames";
import { useRouter } from "next/router";
import {reportNavigation} from '../../../../app/routes';

// TODO: Refactor this out to state

const navigation = [
  { name: "Dashboard", icon: <IconNavDashboard />, href: "/dashboard" },
  { name: "Clients", icon: <IconNavClients />, href: "/clients" },
  { name: "Consultants", icon: <IconNavSpeech />, href: "/consultants" },
  { name: "Settings", icon: <IconNavSettings />, href: "/settings" },
];

export default function SideNavigation() {
  const router = useRouter();
  const [showSecondary, setShowSecondary] = useState(true);

  return (
    <div className="hidden sm:block sticky top-[86px] h-[calc(100vh-86.5px)]">
      <div className="flex flex-col flex-grow h-full overflow-y-auto border-r border-primary-100 bg-primary-dark-100 sm:block">
        <nav className="flex flex-1 h-full space-y-0" aria-label="Sidebar">
          <div className="w-24">
            {navigation.map((item, index) => (
              <Link href={item.href} key={index}>
                <a
                  className={classNames(
                    "h-16",
                     router.asPath.includes(item.href) === true
                      ? "bg-primary-100 text-white"
                      : "text-primary-100",
                    "flex items-center justify-center px-4 py-4 text-sm font-medium hover:bg-primary-100 hover:text-white"
                  )}
                >
                  <span className="w-6">{item.icon}</span>
                </a>
              </Link>
            ))}
          </div>

          {router.asPath.includes("reports") && (
            <div className="w-40 text-white border-l border-primary-dark-50">
              {reportNavigation.map((item, index) => (
                <Link
                  href={{
                    pathname: item.href,
                    query: {
                      client_id: router.query.client_id,
                      survey_id: router.query.survey_id,
                    },
                  }}
                  key={index}
                >
                  <a
                    className={classNames(
                      "flex h-16 items-center px-3 py-4 text-sm hover:bg-primary-100",
                      item.href == router.pathname
                        ? "bg-primary-100"
                        : "hover:text-gray-900"
                    )}
                  >
                    {item.name}
                  </a>
                </Link>
              ))}
            </div>
          )}
        </nav>
      </div>
    </div>
  );
}
