import { WithApiAuthRequired } from "@auth0/nextjs-auth0";
import { kxLog } from "app/kx";
class HttpClient {
  constructor(options = {}) {
    this._baseURL = options.baseURL || "FAIL";
    this._headers = options.headers || {};
    this.authHeader = options.authHeader || {};
  }

  async _fetchJSON(endpoint, options = {}) {
    const res = await fetch(this._baseURL + endpoint, {
      ...options,
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        ...this.authHeader,
      },
    });

    // TODO improve error handling here. 401 could be expired token or incorrect scope to update API endpoint
    if (res.ok == false) {
      if (res.status === 401) {
        return {
          auth: "expiredToken",
          message: res.err?.message | "401 Forbidden - expired token",
        };
      }
    }

    if (options.parseResponse !== false && res.status !== 204)
      return res.json();

    return undefined;
  }

  async _postFile(endpoint, file, fields) {

    var formData = new FormData();
    formData.append("file", file);

    if (fields) {
      for (const field in fields) {
        formData.append(field, fields[field]);
      }
    }

    const res = await fetch(this._baseURL + endpoint, {
      method: "POST",
      body: formData,
      headers: {
        accept: "application/json",
        ...this.authHeader,
      },
    });

    let text = await res.text(); 
    let data = null;
    
    if (text != '') {
      // if trailing comma present, remove last character by slicing it off
      // -1 in slice is the same as text.length - 1
      if (text.endsWith(",")) text = text.slice(0, -1);
      data = JSON.parse(text);
      data = data.data
    }

    // The HTTP 201 Created success status response code indicates that the request has succeeded and has led to the creation of a resource.
    if (res.ok) 
      return { statusCode: res.status, data: data, json: JSON.parse(text) }

      try {
        return await res.json()
      } catch(e) {
        return JSON.parse(text)
      }
    
  }

  setHeader(key, value) {
    this._headers[key] = value;
    return this;
  }

  setBaseURL(url) {
    this._baseURL = url;
  }

  getHeader(key) {
    return this._headers[key];
  }

  setBearerAuth(token) {
    this._headers.Authorization = `Bearer ${token}`;
    return this;
  }

  get(endpoint, options = {}) {
    return this._fetchJSON(endpoint, {
      ...options,
      method: "GET",
    });
  }

  post(endpoint, body, options = {}) {
    return this._fetchJSON(endpoint, {
      ...options,
      body: body ? JSON.stringify(body) : JSON.stringify({}),
      method: "POST",
    });
  }

  postFile(endpoint, file, fields) {
    return this._postFile(endpoint, file, fields);
  }

  put(endpoint, body, options = {}) {
    return this._fetchJSON(endpoint, {
      ...options,
      body: body ? JSON.stringify(body) : undefined,
      method: "PUT",
    });
  }

  patch(endpoint, operations, options = {}) {
    return this._fetchJSON(endpoint, {
      parseResponse: false,
      ...options,
      body: JSON.stringify(operations),
      method: "PATCH",
    });
  }

  delete(endpoint, options = {}) {
    return this._fetchJSON(endpoint, {
      parseResponse: false,
      body: JSON.stringify({}),
      ...options,
      method: "DELETE",
    });
  }
}

export default HttpClient;
